import React from 'react';
import Form from '../../../../components/admin/messages/form';
import AdminLayout from '../../../../components/layouts/admin-layout';
import BackButton from '../../../../components/ui/BackButton';

import './message-form.css';

type MessageFormProps = {
  readonly location: {
    search: string;
  };
};

function MessageForm({ location }: MessageFormProps): JSX.Element {
  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get('id');

  return (
    <AdminLayout title={'DEVstart | Cadastrar Mensagem'}>
      <div className='button-back-area'>
        <BackButton prevUrl='/admin/messages/message-dashboard' />
      </div>

      <div className='new-message-container'>
        <h1>{id ? 'Edição de mensagem' : 'Cadastro de mensagem'}</h1>
        <Form messageId={id} />
      </div>
    </AdminLayout>
  );
}

MessageForm.displayName = 'MessageForm';

export default MessageForm;
