import React from 'react';

function ArrowLeft2(
  props: JSX.IntrinsicAttributes & React.SVGProps<SVGSVGElement>
): JSX.Element {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='18'
      fill='none'
      viewBox='0 0 18 18'
      {...props}
    >
      <mask
        id='mask0_3040_5897'
        style={{ maskType: 'alpha' }}
        width='18'
        height='18'
        x='0'
        y='0'
        maskUnits='userSpaceOnUse'
      >
        <path fill='#D9D9D9' d='M0 0H18V18H0z'></path>
      </mask>
      <g mask='url(#mask0_3040_5897)'>
        <path
          fill='#333'
          d='M7.464 13.24L3.224 9l4.24-4.24.79.813L5.39 8.438h9.387v1.125H5.39l2.864 2.864-.79.814z'
        ></path>
      </g>
    </svg>
  );
}

ArrowLeft2.displayName = 'ArrowLeft2';

export default ArrowLeft2;
